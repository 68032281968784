<template>
  <div style="height: 80%" v-if="notLoading">
    <e-charts ref="line" autoresize :options="option" auto-resize />
  </div>
</template>

<script>
import DashboardCallsService from "@/views/commons/components/ring-central/dashboard/dashboard.service.js";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import { graphic } from "echarts";
import ECharts from "vue-echarts";

export default {
  components: {
    ECharts,
  },

  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    lineId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
  data() {
    return {
      notLoading: false,
      // gradiant: new ECharts.graphic(),
      option: {},
    };
  },

  methods: {
    async getFrecuencyClientsContacts() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          year: this.year,
          line_id: this.lineId,
        };
        const { data } =
          await DashboardCallsService.getFrecuencyClientsContacts(params);

        this.option.xAxis.data = data
          .map((item) => {
            if (item.rtype == "lead") {
              return item.month;
            }
            return null;
          })
          .filter((valor) => valor !== null);

        this.option.series[0].symbol =
          "image://" + require("@/assets/images/icons/IconBall.png");
        this.option.series[1].symbol =
          "image://" + require("@/assets/images/icons/IconBall2.png");

        this.option.series[0].data = data
          .map((item) => {
            if (item.rtype == "lead") {
              return item.total == 0 ? 0 : item.total;
            }
            return null;
          })
          .filter((valor) => valor !== null);

        this.option.series[1].data = data
          .map((item) => {
            if (item.rtype == "client") {
              return item.total == 0 ? 0 : item.total;
            }
            return null;
          })
          .filter((valor) => valor !== null);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    (this.option = {
      showLoading: false,

      grid: {
        width: "95%",
        left: "40px",
        right: "4%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
      },

      legend: {
        top: "top",
        padding: 10,
        data: ["Leads", "Clients"],
        color: ["#3F7AFA", "#A4E5FF"],
        itemHeight: 20,
        itemWidth: 20,

        textStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#17171A",
        },
      },

      xAxis: {
        type: "category",
        data: [],
        boundaryGap: false,

        axisLabel: {
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
          margin: 20,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.isDarkTheme ? "#4E4E4E" : "#dddddd",
            width: 1,
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}",
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
          fontSize: 15,
        },
        nameTextStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
        },
        axisLine: {
          show: true,
          onZero: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.isDarkTheme ? "#FFFFFF" : "#616161",
            type: "dashed",
            width: 1.3,
            interval: 10,
          },
        },
      },
      series: [
        {
          name: "Leads",
          type: "line",
          data: [],

          symbolSize: 20, // tamano de la bolita

          lineStyle: {
            width: 4,
            color: "#3F7AFA",
            shadowColor: "rgba(0,0,0,0.3)",
            shadowBlur: 10,
            shadowOffsetY: 8,
          },

          itemStyle: {
            color: "rgba(54, 162, 235, 1)",
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(0, 144, 231, 0.8)",
              },
              {
                offset: 1,
                color: "rgba(0, 144, 231, 0.28)",
              },
            ]),
            // color: "red",
          },

          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
        },
        {
          name: "Clients",
          type: "line",
          data: [],

          symbolSize: 20,
          symbol: "circle",

          lineStyle: {
            width: 4,
            color: "#A4E5FF",
            shadowColor: "rgba(0,0,0,0.3)",
            shadowBlur: 10,
            shadowOffsetY: 8,
          },

          itemStyle: {
            color: "#A4E5FF",
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(164, 229, 255, 0.8)",
              },
              {
                offset: 1,
                color: "rgba(164, 229, 255, 0.28)",
              },
            ]),
            // color: "red",
          },

          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
        },
      ],
    }),
      await this.getFrecuencyClientsContacts();

    this.notLoading = true;
  },

  watch: {
    year() {
      this.getFrecuencyClientsContacts();
    },

    moduleId() {
      this.getFrecuencyClientsContacts();
    },

    lineId() {
      this.getFrecuencyClientsContacts();
    },

    isDarkSkin: {
      handler: function (val, oldVal) {
        this.option.xAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.option.xAxis.splitLine.lineStyle.color = val
          ? "#4E4E4E"
          : "#dddddd";

        this.option.yAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.option.yAxis.splitLine.lineStyle.color = val
          ? "#FFFFFF"
          : "#616161";
        this.option.legend.textStyle.color = val ? "#FFFFFF" : "#17171A";
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>