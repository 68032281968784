<template>
  <div>
    <header-slot no-border-bottom> </header-slot>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: $route.matched[1].meta.callLogInternalRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Internal</b-nav-item
      >
      <b-nav-item
        :to="{ name: $route.matched[1].meta.callLogExternalRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="inSubTab"
        >External</b-nav-item
      >

      <b-nav-item
        :to="{ name: $route.matched[1].meta.callLogOthersRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Others</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>


<script>
export default {
  computed: {
    inSubTab() {
      const { callLogExternalInboundRoute, callLogExternalOutboundRoute } =
        this.$route.matched[2].meta;
      return [
        callLogExternalInboundRoute,
        callLogExternalOutboundRoute,
      ].includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
</style>
