<template>
  <div class="bg-image-call-item">
    <b-img :src="image"></b-img>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 31,
    },
    height: {
      type: Number,
      required: false,
      default: 30,
    },
  },

  data() {
    return {
      image: require("@/assets/images/icons/CallTime.png"),
    };
  },
};
</script>

<style lang="scss">
.bg-image-call-item {
  background-color: #dfdfdf;
  border-radius: 10px;
  padding: 10px;
}

.dark-layout {
  .bg-image-call-item {
    background-color: #272729;
  }
}
</style>