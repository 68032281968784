<template>
  <div>
    <header-slot :clients-search="true" :global-search="true">
      <template #actions>
        <b-row class="w-100">
          <!-- Title Search Global -->
          <!-- Input Search Global -->
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="globalSearchText"
                placeholder="Search by Name, Account or Case Number"
                @keyup.enter="searchAllCases"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchAllCases">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in dataTabs"
        :key="index"
        :to="{ name: item.route }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        {{ item.name }}
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <GlobalSearchCourtCasesModal
      v-if="showGlobalSearchModal"
      :search-text="globalSearchText"
      @hidden="showGlobalSearchModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GlobalSearchCourtCasesModal from "./components/GlobalSearchCourtCasesModal.vue";
export default {
  components: {
    GlobalSearchCourtCasesModal,
  },
  data() {
    return {
      globalSearchText: "",
      showGlobalSearchModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    dataTabs() {
      return this.$route.matched[1].meta.tabs;
    },
  },
  methods: {
    searchAllCases() {
      this.openGlobalSearchModal();
    },
    openGlobalSearchModal() {
      this.showGlobalSearchModal = true;
    },
    closeGlobalSearchModal() {
      this.showGlobalSearchModal = false;
    },
  },
};
</script>