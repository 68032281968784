<template>
  <div style="height: 80%; width: 90%">
    <e-charts ref="line" :options="option" autoresize />
  </div>
</template>

<script>
import DashboardCallsService from "@/views/commons/components/ring-central/dashboard/dashboard.service.js";
import { mapState } from "vuex";
import ECharts from "vue-echarts";
export default {
  components: {
    ECharts,
  },

  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    lineId: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
    ...mapState({
      windowWidth: (state) => state.app.windowWidth,
    }),
  },

  data() {
    return {
      itemsConfig: [
        {
          color: "#2453BA",
          label: "Ultra-Short Calls",
          description: "<1M",
          value: 0,
        },
        {
          color: "#3F7AFA",
          label: "Short Calls",
          description: "1-3M",
          value: 10,
        },
        {
          color: "#539BC3",
          label: "Standard Calls",
          description: "3-6M",
          value: 0,
        },
        { color: "#81A8FB", label: "Long Calls", description: ">6M", value: 0 },
      ],

      option: {},
    };
  },

  methods: {
    async getCallsByDuration() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          from: this.day.startDate,
          to: this.day.endDate,
          line_id: this.lineId,
        };
        const { data } = await DashboardCallsService.getCallsByDuration(params);
        this.option.series[0].data = data;
        this.option.legend.data = data.map((obj) => obj.name);
        this.itemsConfig[0].value = data[0].value;
        this.itemsConfig[1].value = data[1].value;
        this.itemsConfig[2].value = data[2].value;
        this.itemsConfig[3].value = data[3].value;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    this.option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        top: "center",
        left: "left",
        orient: "vertical",
        align: "auto",
        itemWidth: 30,
        itemHeight: 30,
        itemGap: 24,
        backgroundColor: this.isDarkTheme ? "#272729" : "#dfdfdf",
        borderRadius: 10,
        padding: 20,
        data: [],
        // color: (element) => {
        //   return this.itemsConfig[element.dataIndex];
        // },
        textStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#17171A",
          // lineHeight: 2,
          rich: {
            a: {
              // lineHeight: 10,
              height: 20,
              fontWeight: "bold",
              fontSize: 15,
            },
            b: {
              height: 10,
              fontSize: 12,
            },
          },
        },

        formatter: (name) => {
          const item = this.itemsConfig.find((item) => item.label == name);
          return [
            "{a|" + name + "} {x| (" + item.description + ")}",
            "{b|( " + item.value + " Calls)}",
          ].join("\n");
        },
      },
      series: [
        {
          name: "Duration",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["70%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          data: [],
          itemStyle: {
            // opacity: 0.7,
            color: (element) => {
              return this.itemsConfig[element.dataIndex].color;
            },
            borderWidth: 10,
            borderRadius: 30,
            borderColor: this.isDarkTheme ? "#17171A" : "#FFFFFF",
          },
          labelLine: {
            show: false,
          },
          // emphasis: {
          //   label: {
          //     show: true,
          //     fontSize: 40,
          //     fontWeight: "bold",
          //   },
          // },
        },
      ],
    };
    await this.getCallsByDuration();
  },

  watch: {
    day() {
      this.getCallsByDuration();
    },

    moduleId() {
      this.getCallsByDuration();
    },

    lineId() {
      this.getCallsByDuration();
    },

    isDarkSkin: {
      handler: function (val, oldVal) {
        this.option.series[0].itemStyle.borderColor = val
          ? "#17171A"
          : "#FFFFFF";

        this.option.legend.textStyle.color = val ? "#FFFFFF" : "#17171A";
        this.option.legend.backgroundColor = val ? "#272729" : "#dfdfdf";
      },
      deep: true,
    },

    windowWidth: {
      handler: function (val) {
        if (val <= 1373 && val >= 991) {
          this.option.legend.orient = "horizontal";
          this.option.legend.top = "top";
          this.option.series[0].center = ["50%", "70%"];
        } else {
          this.option.legend.orient = "vertical";
          this.option.legend.top = "center";
          this.option.series[0].center = ["70%", "50%"];
        }
      },
    },
  },
};
</script>

<style>
</style>