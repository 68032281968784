<template>
  <div>
    <b-modal
      modal
      title="GLOBAL SEARCH"
      ref="globalSearchCourtCasesRef"
      size="xl"
      modal-class="modal-primary text-center"
      hide-footer
      @hidden="$emit('hidden')"
    >
      <CiGridTable :search-text="searchText" :is-global-search="true" />
    </b-modal>
  </div>
</template>
<script>
import CiGridTable from "@/views/debt-solution/views/court-info/components/CIGridTable.vue";
export default {
  name: "GlobalSearchCourtCasesModal",
  components: {
    CiGridTable,
  },
  props: {
    searchText: {
      type: String,
      required: true,
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.toggleModal("globalSearchCourtCasesRef");
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
