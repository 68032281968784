<template>
  <div class="dashboard-calls-container">
    <header-slot>
      <template #actions>
        <div class="d-flex justify-content-end">
          <div
            class="modules d-flex justify-content-end align-items-center"
            v-if="moduleId == 16"
          >
            Module:
            <v-select
              class="mx-1"
              :options="modules"
              v-model="currentModuleId"
              label="name"
              :reduce="(option) => option.id"
              :clearable="false"
            >
              <template #selected-option="option">
                <div>
                  {{ option.name | limitChars(19) }}
                </div>
              </template>
            </v-select>
          </div>
          <div class="modules d-flex justify-content-end align-items-center">
            Line:
            <v-select
              class="mx-1"
              style="width: 340px"
              :options="numbers"
              v-model="currentLineId"
              label="number_format"
              :reduce="(option) => option.id"
              :clearable="false"
            >
              <template #selected-option="option">
                <div style="width: 250px; text-align: left">
                  <div class="text-truncate">
                    {{ option.number_format }} -
                    {{ option.rc_name }}
                  </div>
                </div>
              </template>

              <template #option="option">
                <div style="width: 250px; text-align: left">
                  <div class="text-truncate">
                    {{ option.number_format }} -
                    {{ option.rc_name }}
                  </div>
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </template>
    </header-slot>
    <div>
      <b-row v-if="!loading">
        <b-col xl="6" lg="6" cols="12">
          <div class="container-card card">
            <div class="container-title">
              <div class="d-flex align-items-center">
                <call-time-icon />
                <div class="title-chart">Customer Contacts Quantity</div>
              </div>
              <div class="select-chart">
                <v-select
                  class="mr-1"
                  :options="optionsYears"
                  v-model="year"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                ></v-select>
              </div>
            </div>

            <line-chart-by-clients
              :module-id="moduleId == 16 ? currentModuleId : moduleId"
              :year="year"
              :lineId="currentLineId"
            />
          </div>
        </b-col>
        <b-col xl="6" lg="6" cols="12">
          <div class="container-card card">
            <div class="container-title">
              <call-time-icon />
              <div class="title-chart">Calls Per Hour</div>
              <div class="select-chart d-flex">
                <b-form-group label="Direction: ">
                  <v-select
                    class="mr-1"
                    :options="optionsCall4Hour"
                    v-model="selectCall4hour"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
                <b-form-group label="Date: ">
                  <date-range-picker
                    ref="picker"
                    class="mr-1"
                    :opens="'left'"
                    control-container-class="vue-daterange-picker-input"
                    appendToBody
                    :ranges="ranges"
                    :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
                    v-model="dayCall4Hour"
                  >
                    <template v-slot:input="picker">
                      {{ picker.startDate | myGlobal }} -
                      {{ picker.endDate | myGlobal }}
                      <feather-icon
                        icon="CalendarIcon"
                        size="19"
                        class="text-success ml-2"
                      />
                    </template>
                  </date-range-picker>
                </b-form-group>
              </div>
            </div>
            <bar-chart-call-by-hour
              ref="barChartCallByHour"
              :module-id="moduleId == 16 ? currentModuleId : moduleId"
              :type="selectCall4hour"
              :day="dayCall4Hour"
              :lineId="currentLineId"
            />
          </div>
        </b-col>
        <b-col xl="6" lg="6" cols="12" class="mt-2">
          <div class="container-card card">
            <div class="container-title d-flex">
              <call-time-icon />
              <div class="title-chart">Calls Duration</div>
              <div class="ml-auto">
                <date-range-picker
                  ref="picker"
                  :opens="'left'"
                  control-container-class="vue-daterange-picker-input"
                  appendToBody
                  :ranges="ranges"
                  :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
                  v-model="dayCall4Duration"
                >
                  <template v-slot:input="picker">
                    {{ picker.startDate | myGlobal }} -
                    {{ picker.endDate | myGlobal }}
                    <feather-icon
                      icon="CalendarIcon"
                      size="19"
                      class="text-success ml-2"
                    />
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-end">
              <pie-chart-call-by-duration
                :module-id="moduleId == 16 ? currentModuleId : moduleId"
                :day="dayCall4Duration"
                :lineId="currentLineId"
              />
            </div>
          </div>
        </b-col>
        <b-col xl="6" lg="6" cols="12" class="mt-2">
          <div class="container-card card">
            <div class="container-title">
              <call-time-icon />
              <div class="title-chart">Calls Quantity</div>
              <div class="select-chart d-flex">
                <b-form-group label="Direction: ">
                  <v-select
                    class="mr-1"
                    :options="optionsCall4Hour"
                    v-model="selectCallRange"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
                <b-form-group label="Type: ">
                  <v-select
                    class="mr-1"
                    :options="optionsRanges"
                    v-model="selectRange"
                    label="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
              </div>
            </div>

            <div class="custom-inputs-ds">
              <date-range-picker
                v-if="selectRange == 1"
                ref="picker"
                class="mr-1"
                :opens="'left'"
                control-container-class="vue-daterange-picker-input"
                appendToBody
                :ranges="ranges"
                :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
                v-model="dateRange"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | myGlobal }} -
                  {{ picker.endDate | myGlobal }}
                  <feather-icon
                    icon="CalendarIcon"
                    size="19"
                    class="text-success ml-2"
                  />
                </template>
              </date-range-picker>
              <b-form-group label="Month: " v-if="selectRange == 2">
                <v-select
                  class="mr-1"
                  :options="optionsMonths"
                  v-model="monthCountMonth"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                ></v-select>
              </b-form-group>
              <b-form-group
                label="Year: "
                v-if="selectRange == 3 || selectRange == 2"
              >
                <v-select
                  class="mr-1"
                  :options="optionsYears"
                  v-model="yearCountMonth"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                ></v-select>
              </b-form-group>
            </div>

            <calls-by-range-date
              ref="barChartCallByHour"
              :module-id="moduleId == 16 ? currentModuleId : moduleId"
              :type="selectCallRange"
              :searchDate="dateRange"
              :typeAgg="selectRange"
              :year="yearCountMonth"
              :month="monthCountMonth"
              :lineId="currentLineId"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import RCService from "@/views/management/views/settings/components/ring-central/services/RCService.js";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import PieChartCallByDuration from "@/views/commons/components/ring-central/dashboard/components/PieChart.vue";
import BarChartCallByHour from "@/views/commons/components/ring-central/dashboard/components/BarChart.vue";
import LineChartByClients from "@/views/commons/components/ring-central/dashboard/components/LineChart.vue";
import CallsByRangeDate from "@/views/commons/components/ring-central/dashboard/components/CallsByRangeDate.vue";
import DashboardCallsService from "@/views/commons/components/ring-central/dashboard/dashboard.service.js";
import RingcentralService from "@/service/commons/ringcentral.service.js";

import CallTimeIcon from "@/icons/CallTimeIcon.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DashboardCall",
  components: {
    BarChartCallByHour,
    PieChartCallByDuration,
    LineChartByClients,
    CallsByRangeDate,
    CallTimeIcon,
    DateRangePicker,
  },
  computed: {
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },
  data() {
    return {
      monthCountMonth: 1,
      yearCountMonth: 2023,
      year: 2023,
      loading: true,

      currentModuleId: null,

      dayCall4Hour: {
        startDate: null,
        endDate: null,
      },

      dayCall4Duration: {
        startDate: null,
        endDate: null,
      },

      selectCall4hour: 1,
      selectCallRange: 1,
      optionsCall4Hour: [
        { id: 1, name: "OUTBOUND" },
        { id: 2, name: "INBOUND" },
      ],

      optionsMonths: [
        { id: "01", name: "JAN" },
        { id: "02", name: "FEB" },
        { id: "03", name: "MAR" },
        { id: "04", name: "APR" },
        { id: "05", name: "MAY" },
        { id: "06", name: "JUNE" },
        { id: "07", name: "JULY" },
        { id: "08", name: "AUG" },
        { id: "09", name: "SEP" },
        { id: "10", name: "OCT" },
        { id: "11", name: "NOV" },
        { id: "12", name: "DEC" },
      ],
      optionsRanges: [
        { id: 1, name: "RANGE" },
        { id: 2, name: "MONTH" },
        { id: 3, name: "YEAR" },
      ],
      optionsYears: [],
      modules: [],
      selectRange: 1,

      ranges: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      numbers: [],
      currentLineId: null,
    };
  },

  methods: {
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.monthCountMonth = this.optionsMonths.filter(
        (element) => element.id == currentMonth
      )[0].id;
    },

    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const yearPrevius = [];

      for (let i = 0; i < 10; i++) {
        const lastYear = currentYear - i;
        if (lastYear == 2022) break;
        yearPrevius.push(lastYear);
      }

      this.optionsYears = yearPrevius.map((element) => ({
        id: element,
        name: "" + element,
      }));
      this.year = this.optionsYears[0].id;
      this.yearCountMonth = this.optionsYears[0].id;
    },

    generateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      this.dateRange.startDate = yesterday;
      this.dateRange.endDate = yesterday;
      this.dayCall4Hour.startDate = yesterday;
      this.dayCall4Hour.endDate = yesterday;
      this.dayCall4Duration.startDate = yesterday;
      this.dayCall4Duration.endDate = yesterday;

      let last7 = new Date();
      last7.setDate(today.getDate() - 8);
      last7.setHours(0, 0, 0, 0);

      let last14 = new Date();
      last14.setDate(today.getDate() - 15);
      last14.setHours(0, 0, 0, 0);

      let last30 = new Date();
      last30.setDate(today.getDate() - 31);
      last30.setHours(0, 0, 0, 0);

      // Obtener primer día del mes actual
      let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      // Obtener último día del mes actual
      let endDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      this.ranges = {
        Yesterday: [yesterday, yesterday],
        "Last 7 days": [last7, yesterday],
        "Last 14 days": [last14, yesterday],
        "Last 30 days": [last30, yesterday],
        "This month": [firstDay, endDay],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      };
    },

    async getModules() {
      const data = await RCService.getModules();
      this.modules = data;
      this.modules.unshift({ id: 0, name: "ALL" });
      this.currentModuleId = 0;
    },

    async getNumberByModule() {
      const { data } = await RingcentralService.getCredentialsByModuleId(
        this.moduleId == 16 ? this.currentModuleId : this.moduleId
      );
      this.numbers = data;
      this.numbers.unshift({ id: 0, rc_name: "ALL" });
      this.currentLineId = 0;
    },
  },

  watch: {
    currentModuleId(val) {
      this.getNumberByModule();
    },
  },
  async created() {
    if (this.moduleId == 16) {
      await this.getModules();
    }

    await this.generateRanges();
    await this.getNumberByModule();
    this.loading = false;
  },
  mounted() {
    this.generateYears();
    this.currentMonth();
  },

  beforeRouteEnter(to, from, next) {
    const userData = getUserData();

    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
};
</script>

<style lang="scss">
.dashboard-calls-container {
  font-family: "Rubick";

  .modules {
    .v-select {
      min-width: 240px;
    }
  }
  .v-select {
    min-width: 150px;
  }

  .custom-inputs-ds {
    display: flex;
    justify-content: end;
    margin-right: 30px;
  }

  .container-card {
    height: 100%;
    padding-top: 2px;
    border-radius: 20px;

    .container-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 90px;
      margin: 15px 30px 0 30px;

      .title-chart {
        font-size: 22px;
        color: #17171a;
        margin-left: 15px;
      }

      .select-chart {
        margin-left: auto;
      }
    }
  }
}

.dark-layout {
  .dashboard-calls-container {
    .container-card {
      .container-title {
        .title-chart {
          color: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 1373px) and (min-width: 992px) {
  .container-card {
    padding-top: 10px;

    .container-title {
      .select-chart {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .custom-inputs-ds {
      margin-top: 33px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>