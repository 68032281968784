<template>
  <div>
    <header-slot></header-slot>
     <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: 'pending-permission-action-section-debtsolution'}"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Pending</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'finished-permission-action-section-debtsolution'}"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Finished</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>